import React, { useEffect, useState } from "react";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";

const BlogListing = ({ data }) => {
  const [lang, setLang] = useState();
  const topData =
    data?.filter((top) => top.attributes?.Top_Read === true) || [];

  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      setLang("ar");
    } else {
      setLang("en");
    }
  }, []);

  return (
    <ParentContainer containerStyle={"flex flex-wrap"}>
      {/* Featured Blog (First Item) */}
      {topData.length > 0 && topData[0]?.attributes && (
        <a
          href={
            lang === "ar"
              ? `/ar/blog-single/${topData[0].attributes.Title?.replaceAll(
                  "-",
                  "^"
                )
                  .replaceAll(" ", "-")
                  .replaceAll("?", "&*")}`
              : `/blog-single/${topData[0].attributes.Title?.replaceAll(
                  "-",
                  "^"
                )
                  .replaceAll(" ", "-")
                  .replaceAll("?", "&*")}`
          }
          className="w-1/2 flex flex-col gap-3 max-lg:w-full"
        >
          {topData[0].attributes.Image?.data?.attributes?.url && (
            <img
              src={ApiUrl + topData[0].attributes.Image.data.attributes.url}
              alt={topData[0].attributes.Title || "Blog Image"}
              className="object-cover h-[300px] rounded-lg"
            />
          )}
          {topData[0].attributes.blog_category?.data?.attributes?.Category && (
            <p className="text-xs text-gray-500">
              {topData[0].attributes.blog_category.data.attributes.Category}
            </p>
          )}
          {topData[0].attributes.Title && (
            <h2 className="text-2xl font-semibold underline max-md:text-xl">
              {topData[0].attributes.Title}
            </h2>
          )}
        </a>
      )}

      {/* Top Read Blogs List */}
      <div className="w-1/2 pl-5 rtl:pl-0 rtl:pr-5 flex flex-col gap-2 max-lg:w-full max-lg:pl-0">
        <div className="border-b border-custom-primary pb-2 max-lg:border-t max-lg:border-b-0 max-lg:mt-4 max-lg:pt-4">
          <h3 className="text-2xl text-custom-primary font-semibold">
            {lang == "ar" ? "أكثر المدونات قراءة" : "Top Read Blogs"}
          </h3>
        </div>
        <div className="flex flex-col">
          {topData.length > 1 ? (
            topData.slice(1, 5).map(
              (top, index) =>
                top.attributes?.Title && (
                  <a
                    key={index}
                    href={`/ar/blog-single/${top.attributes.Title.replaceAll(
                      "-",
                      "^"
                    )
                      .replaceAll(" ", "-")
                      .replaceAll("?", "&*")}`}
                    className="py-5"
                  >
                    {top.attributes.blog_category?.data?.attributes
                      ?.Category && (
                      <p className="text-xs text-gray-500">
                        {top.attributes.blog_category.data.attributes.Category}
                      </p>
                    )}
                    <h2 className="text-xl font-semibold underline max-md:text-base">
                      {top.attributes.Title}
                    </h2>
                  </a>
                )
            )
          ) : (
            <p className="text-gray-500">
              No additional top-read blogs available.
            </p>
          )}
        </div>
      </div>
    </ParentContainer>
  );
};

export default BlogListing;
