import React, { useEffect, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";
import assets from "../../assets/assets";
import { MainUrl } from "../../components/MainUrl";

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(false);

  const [telrData, setTelrData] = useState(null);
  const [formData, setFormData] = useState({
    giftAmount: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    recipientFirstName: "",
    recipientLastName: "",
    recipientPhone: "",
    recipientEmail: "",
    recipientAddress: "",
    recipientCity: "",
    recipientZipCode: "",
    recipientCountry: "",
    billingAddress: "same",
    howToSend: "",
  });

  const [showNewAddress, setShowNewAddress] = useState(false);
  const [address, setAddress] = useState("same");

  const [status, setStatus] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const successParam = params.get("success");
    const failedParam = params.get("failed");
    const idParam = params.get("id");

    if (successParam !== null) {
      setStatus("success");
    } else if (failedParam !== null) {
      setStatus("failed");
    }

    if (idParam !== null) {
      setId(idParam);
    }
  }, []);

  useEffect(() => {
    if (status === "success" && id !== null) {
      checkCoupon();
    }
  }, [status, id]);

  const checkCoupon = async () => {
    if (status === "success" && id !== null) {
      try {
        const couponResponse = await axios.post(
          ApiUrl + "/api/telr/check-session",
          {
            orderid: id,
          }
        );
        console.log("Coupon updated successfully:", couponResponse.data);
      } catch (error) {
        console.error("Error creating coupon:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, phone }));
  };

  const handleRecipientPhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, recipientPhone: phone }));
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormData((prev) => ({ ...prev, giftAmount: option }));
  };

  const handleRadioChange = (e) => {
    setAddress(e.target.value);
    if (e.target.value === "new") {
      setShowNewAddress(true);
      setFormData((prev) => ({ ...prev, billingAddress: "" }));
    } else {
      setShowNewAddress(false);
      setFormData((prev) => ({ ...prev, billingAddress: "same" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let redirectUrl = null;

    try {
      // First API call: create coupon
      const couponResponse = await axios.post(ApiUrl + "/api/coupons", {
        data: {
          Amount: Number(formData.giftAmount),
        },
      });
      console.log("Coupon Created successfully:", couponResponse.data);
      setCoupon(couponResponse.data);

      // Second API call: create a Telr session
      const telrResponse = await axios.post(
        ApiUrl + "/api/telr/create-session",
        {
          orderDetails: {
            cartid: String(couponResponse.data.data.id),
            amount: String(formData.giftAmount),
            description: "Coupon Order",
            test: "1",
          },
          returnUrls: {
            authorised: `${MainUrl}/gift-registry?success&id=${String(
              couponResponse.data.data.id
            )}`,
            declined: `${MainUrl}/gift-registry?failed&id=${String(
              couponResponse.data.data.id
            )}`,
            cancelled: `${MainUrl}/gift-registry?failed&id=${String(
              couponResponse.data.data.id
            )}`,
          },
          customerDetails: {
            ref: "guest",
            email: formData.email,
            name: {
              forenames: formData.firstName,
              surname: formData.lastName,
            },
            address: {
              line1: formData.address,
              city: formData.city,
              state: formData.city,
              country: formData.country,
              areacode: formData.zipCode,
            },
            phone: formData.phone,
          },
        }
      );
      console.log("TelrData Created successfully:", telrResponse.data);
      setTelrData(telrResponse.data);

      redirectUrl = telrResponse.data.order.url;
    } catch (error) {
      console.error("Error in coupon or Telr session creation:", error);
    }

    // Send email and Salesforce data
    try {
      // Prepare recipient email data
      const recipientEmails =
        formData.email && formData.recipientEmail
          ? `${formData.email}, ${formData.recipientEmail}, infoarabia@cellsave.com, marketing@cellsave.com, cs-callcenter@cellsave.com, tala.hammami@cellsave.com, cs-agents@cellsave.com`
          : "infoarabia@cellsave.com, marketing@cellsave.com, cs-callcenter@cellsave.com, tala.hammami@cellsave.com, cs-agents@cellsave.com";

      const subject = "New Gift Registry";
      const text = "You have a new message from the Gift Registry Cellsave.";

      const emailData = {
        to: recipientEmails,
        subject: subject,
        text: text,
        html: `
          <p><strong>First Name:</strong> ${formData.firstName}</p>
          <p><strong>Last Name:</strong> ${formData.lastName}</p>
          <p><strong>Phone Number:</strong> ${formData.phone}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
        `,
      };

      // Prepare Salesforce data
      const saleforceData = {
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Email: formData.email,
        MobilePhone: formData.phone,
        Origin_Program__c: "Gift Registry",
        Lead_Quality__c: "Hot",
        AttendingDoctor__c: "a00f400000XCppCAAT",
        Hospital_Clinics__c: "a01f400000KF5VMAA1",
        Program_Running__c: "Website",
        Origin_Hospital__c: "a01f400000KF5VMAA1",
        Origin_Doctor__c: "a00f400000XCppCAAT",
      };

      const [emailResponse, saleforceResponse] = await Promise.all([
        axios.post(`${ApiUrl}/api/email/send`, emailData),
        axios.post(`${ApiUrl}/api/salesforce`, saleforceData),
      ]);

      console.log("Email sent successfully:", emailResponse.data);
      console.log("Salesforce data sent successfully:", saleforceResponse.data);
    } catch (error) {
      console.error("Error sending email or Salesforce data:", error);
    } finally {
      setLoading(false);
    }

    // Redirect if we have a valid Telr URL
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <ParentContainer containerStyle={"flex flex-wrap "}>
      {status === "success" && (
        <div className="fixed top-1/2 shadow-md rounded-lg left-1/2 z-30 -translate-x-1/2 bg-white ">
          <img src={assets.success} alt="" className="pt-10 px-20 " />
          <p className="text-base text-center pb-10">Coupon Sent</p>
          <div
            className=" absolute left-5 top-5 cursor-pointer"
            onClick={() => setStatus(null)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      {status === "failed" && (
        <div className="fixed top-1/2 shadow-md rounded-lg left-1/2 z-30 -translate-x-1/2 bg-white ">
          <img src={assets.failed} alt="" className="pt-10 px-20 " />
          <p className="text-base text-center pb-10">Coupon Not Created</p>
          <div
            className=" absolute left-5 top-5 cursor-pointer"
            onClick={() => setStatus(null)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      <div dir="ltr" className="flex flex-wrap w-full">
        {/* بداية النموذج */}
        <form dir="rtl" className="w-2/3 max-lg:w-full" onSubmit={handleSubmit}>
          {/* قيمة الهدية */}
          <div className="border-b pb-8">
            <h4 className="text-lg font-semibold mb-2">
              قيمة <span className="text-custom-primary">الهدية</span>
            </h4>
            <div className="grid grid-cols-4 gap-4 w-full max-md:grid-cols-4">
              {["500", "1000", "2500", "4000"].map((option) => (
                <div key={option} className="cursor-pointer">
                  <input
                    type="radio"
                    id={`option${option}`}
                    name="giftAmount"
                    className="sr-only"
                    checked={selectedOption === option}
                    onChange={() => handleOptionChange(option)}
                    required
                  />
                  <label htmlFor={`option${option}`}>
                    <div
                      className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 transition-colors ${
                        selectedOption === option
                          ? "border-custom-primary text-white bg-gradient-to-r from-custom-primary to-custom-secondary"
                          : "hover:border-custom-primary hover:text-custom-primary"
                      }`}
                    >
                      {option} <br />
                      AED
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* معلوماتك */}
          <div className="pt-8 border-b pb-8 grid grid-cols-4 gap-4 max-md:text-sm">
            <h4 className="text-lg font-semibold mb-2 col-span-4">معلوماتك</h4>

            <div className="col-span-2 w-full max-md:col-span-4">
              <label htmlFor="firstName" className="block mb-1">
                الاسم الأول
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="lastName" className="block mb-1">
                اسم العائلة
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="phone" className="block mb-1">
                رقم الهاتف
              </label>
              <div dir="ltr">
                <PhoneInput
                  country={"ae"}
                  className="bg-[#fff] p-3 rounded-lg"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputProps={{
                    required: true,
                    name: "phone",
                  }}
                />
              </div>
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="email" className="block mb-1">
                عنوان البريد الإلكتروني
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-4 max-md:col-span-4">
              <label htmlFor="address" className="block mb-1">
                العنوان
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-4 grid grid-cols-3 gap-4">
              <div className="max-md:col-span-4">
                <label htmlFor="city" className="block mb-1">
                  المدينة
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="max-md:col-span-4">
                <label htmlFor="zipCode" className="block mb-1">
                  الرمز البريدي
                </label>
                <input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="max-md:col-span-4">
                <label htmlFor="country" className="block mb-1">
                  البلد
                </label>
                <select
                  name="country"
                  id="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                >
                  <option value="">--اختر--</option>
                  <option value="af">Afghanistan</option>
                  <option value="al">Albania</option>
                  <option value="dz">Algeria</option>
                  <option value="as">American Samoa</option>
                  <option value="ad">Andorra</option>
                  <option value="ao">Angola</option>
                  <option value="ai">Anguilla</option>
                  <option value="ag">Antigua and Barbuda</option>
                  <option value="ar">Argentina</option>
                  <option value="am">Armenia</option>
                  <option value="aw">Aruba</option>
                  <option value="au">Australia</option>
                  <option value="at">Austria</option>
                  <option value="az">Azerbaijan</option>
                  <option value="bs">Bahamas</option>
                  <option value="bh">Bahrain</option>
                  <option value="bd">Bangladesh</option>
                  <option value="bb">Barbados</option>
                  <option value="by">Belarus</option>
                  <option value="be">Belgium</option>
                  <option value="bz">Belize</option>
                  <option value="bj">Benin</option>
                  <option value="bm">Bermuda</option>
                  <option value="bt">Bhutan</option>
                  <option value="bo">Bolivia</option>
                  <option value="ba">Bosnia and Herzegovina</option>
                  <option value="bw">Botswana</option>
                  <option value="br">Brazil</option>
                  <option value="io">British Indian Ocean Territory</option>
                  <option value="vg">British Virgin Islands</option>
                  <option value="bn">Brunei Darussalam</option>
                  <option value="bg">Bulgaria</option>
                  <option value="bf">Burkina Faso</option>
                  <option value="bi">Burundi</option>
                  <option value="kh">Cambodia</option>
                  <option value="cm">Cameroon</option>
                  <option value="ca">Canada</option>
                  <option value="cv">Cape Verde</option>
                  <option value="ky">Cayman Islands</option>
                  <option value="cf">Central African Rep</option>
                  <option value="td">Chad</option>
                  <option value="cl">Chile</option>
                  <option value="cn">China</option>
                  <option value="cx">Christmas Island</option>
                  <option value="cc">Cocos (Keeling) Islands</option>
                  <option value="co">Colombia</option>
                  <option value="km">Comoros</option>
                  <option value="cd">Congo, Democratic Rep of</option>
                  <option value="cg">Congo, Republic of</option>
                  <option value="ck">Cook Islands</option>
                  <option value="cr">Costa Rica</option>
                  <option value="ci">Cote d'Ivoire</option>
                  <option value="hr">Croatia</option>
                  <option value="cy">Cyprus</option>
                  <option value="cz">Czech Rep</option>
                  <option value="dk">Denmark</option>
                  <option value="dj">Djibouti</option>
                  <option value="dm">Dominica</option>
                  <option value="do">Dominican Rep</option>
                  <option value="ec">Ecuador</option>
                  <option value="eg">Egypt</option>
                  <option value="sv">El Salvador</option>
                  <option value="gq">Equatorial Guinea</option>
                  <option value="er">Eritrea</option>
                  <option value="ee">Estonia</option>
                  <option value="et">Ethiopia</option>
                  <option value="fk">Falkland Islands</option>
                  <option value="fo">Faroe Islands</option>
                  <option value="fj">Fiji</option>
                  <option value="fi">Finland</option>
                  <option value="fr">France</option>
                  <option value="gf">French Guyana</option>
                  <option value="pf">French Polynesia</option>
                  <option value="ga">Gabon</option>
                  <option value="gm">Gambia</option>
                  <option value="ge">Georgia</option>
                  <option value="de">Germany</option>
                  <option value="gh">Ghana</option>
                  <option value="gi">Gibraltar</option>
                  <option value="gr">Greece</option>
                  <option value="gl">Greenland</option>
                  <option value="gd">Grenada</option>
                  <option value="gp">Guadeloupe</option>
                  <option value="gu">Guam</option>
                  <option value="gt">Guatemala</option>
                  <option value="gn">Guinea</option>
                  <option value="gw">Guinea-Bissau</option>
                  <option value="gy">Guyana</option>
                  <option value="ht">Haiti</option>
                  <option value="hn">Honduras</option>
                  <option value="hk">Hong Kong</option>
                  <option value="hu">Hungary</option>
                  <option value="is">Iceland</option>
                  <option value="in">India</option>
                  <option value="id">Indonesia</option>
                  <option value="iq">Iraq</option>
                  <option value="ie">Ireland</option>
                  <option value="il">Israel</option>
                  <option value="it">Italy</option>
                  <option value="jm">Jamaica</option>
                  <option value="jp">Japan</option>
                  <option value="jo">Jordan</option>
                  <option value="kz">Kazakhstan</option>
                  <option value="ke">Kenya</option>
                  <option value="ki">Kiribati</option>
                  <option value="kr">Korea, South</option>
                  <option value="kw">Kuwait</option>
                  <option value="kg">Kyrgyzstan</option>
                  <option value="la">Laos</option>
                  <option value="lv">Latvia</option>
                  <option value="lb">Lebanon</option>
                  <option value="ls">Lesotho</option>
                  <option value="lr">Liberia</option>
                  <option value="ly">Libya</option>
                  <option value="li">Liechtenstein</option>
                  <option value="lt">Lithuania</option>
                  <option value="lu">Luxembourg</option>
                  <option value="mo">Macau</option>
                  <option value="mk">Macedonia</option>
                  <option value="mg">Madagascar</option>
                  <option value="mw">Malawi</option>
                  <option value="my">Malaysia</option>
                  <option value="mv">Maldives</option>
                  <option value="ml">Mali</option>
                  <option value="mt">Malta</option>
                  <option value="mh">Marshall Islands</option>
                  <option value="mq">Martinique</option>
                  <option value="mr">Mauritania</option>
                  <option value="mu">Mauritius</option>
                  <option value="yt">Mayotte</option>
                  <option value="mx">Mexico</option>
                  <option value="fm">Micronesia</option>
                  <option value="md">Moldova, Rep of</option>
                  <option value="mc">Monaco</option>
                  <option value="mn">Mongolia</option>
                  <option value="me">Montenegro</option>
                  <option value="ms">Montserrat</option>
                  <option value="ma">Morocco</option>
                  <option value="mz">Mozambique</option>
                  <option value="mm">Myanmar</option>
                  <option value="na">Namibia</option>
                  <option value="nr">Nauru</option>
                  <option value="np">Nepal</option>
                  <option value="nl">Netherlands</option>
                  <option value="an">Netherlands Antilles</option>
                  <option value="nc">New Caledonia</option>
                  <option value="nz">New Zealand</option>
                  <option value="ni">Nicaragua</option>
                  <option value="ne">Niger</option>
                  <option value="ng">Nigeria</option>
                  <option value="nu">Niue</option>
                  <option value="nf">Norfolk Island</option>
                  <option value="mp">Northern Mariana Islands</option>
                  <option value="no">Norway</option>
                  <option value="om">Oman</option>
                  <option value="pk">Pakistan</option>
                  <option value="pw">Palau</option>
                  <option value="ps">Palestinian Territory, Occupied</option>
                  <option value="pa">Panama</option>
                  <option value="pg">Papua New Guinea</option>
                  <option value="py">Paraguay</option>
                  <option value="pe">Peru</option>
                  <option value="ph">Philippines</option>
                  <option value="pn">Pitcairn Islands</option>
                  <option value="pl">Poland</option>
                  <option value="pt">Portugal</option>
                  <option value="pr">Puerto Rico</option>
                  <option value="qa">Qatar</option>
                  <option value="re">Reunion</option>
                  <option value="ro">Romania</option>
                  <option value="rw">Rwanda</option>
                  <option value="ws">Samoa</option>
                  <option value="sm">San Marino</option>
                  <option value="st">Sao Tome and Principe</option>
                  <option value="sa">Saudi Arabia</option>
                  <option value="sn">Senegal</option>
                  <option value="rs">Serbia</option>
                  <option value="sc">Seychelles</option>
                  <option value="sl">Sierra Leone</option>
                  <option value="sg">Singapore</option>
                  <option value="sk">Slovakia</option>
                  <option value="si">Slovenia</option>
                  <option value="sb">Solomon Islands</option>
                  <option value="so">Somalia</option>
                  <option value="za">South Africa</option>
                  <option value="es">Spain</option>
                  <option value="lk">Sri Lanka</option>
                  <option value="sh">St Helena</option>
                  <option value="kn">St Kitts and Nevis</option>
                  <option value="lc">St Lucia</option>
                  <option value="pm">St Pierre and Miquelon</option>
                  <option value="vc">St Vincent and Grenadines</option>
                  <option value="sr">Suriname</option>
                  <option value="sz">Swaziland</option>
                  <option value="se">Sweden</option>
                  <option value="ch">Switzerland</option>
                  <option value="tw">Taiwan, Rep of China</option>
                  <option value="tj">Tajikistan</option>
                  <option value="tz">Tanzania</option>
                  <option value="th">Thailand</option>
                  <option value="tl">Timor-Leste</option>
                  <option value="tg">Togo</option>
                  <option value="tk">Tokelau</option>
                  <option value="to">Tonga</option>
                  <option value="tt">Trinidad and Tobago</option>
                  <option value="tn">Tunisia</option>
                  <option value="tr">Turkey</option>
                  <option value="tm">Turkmenistan</option>
                  <option value="tc">Turks and Caicos Islands</option>
                  <option value="tv">Tuvalu</option>
                  <option value="ug">Uganda</option>
                  <option value="ae">United Arab Emirates</option>
                  <option value="gb">United Kingdom</option>
                  <option value="vi">United States Virgin Islands</option>
                  <option value="us">United States of America</option>
                  <option value="uy">Uruguay</option>
                  <option value="uz">Uzbekistan</option>
                  <option value="vu">Vanuatu</option>
                  <option value="va">Vatican City</option>
                  <option value="ve">Venezuela</option>
                  <option value="vn">Viet Nam</option>
                  <option value="wf">Wallis and Futuna Islands</option>
                  <option value="eh">Western Sahara</option>
                  <option value="zm">Zambia</option>
                  <option value="zw">Zimbabwe</option>
                  <option value="ss">South Sudan</option>
                </select>
              </div>
            </div>
          </div>

          {/* معلومات المستلم */}
          <div className="pt-8 border-b pb-8 grid grid-cols-4 gap-4 max-md:text-sm">
            <h4 className="text-lg font-semibold mb-2 col-span-4">
              معلومات المستلم
            </h4>

            <div className="col-span-2 w-full max-md:col-span-4">
              <label htmlFor="recipientFirstName" className="block mb-1">
                الاسم الأول للمستلم
              </label>
              <input
                type="text"
                name="recipientFirstName"
                id="recipientFirstName"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.recipientFirstName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="recipientLastName" className="block mb-1">
                اسم العائلة للمستلم
              </label>
              <input
                type="text"
                name="recipientLastName"
                id="recipientLastName"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.recipientLastName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="recipientPhone" className="block mb-1">
                رقم هاتف المستلم
              </label>
              <div dir="ltr">
                <PhoneInput
                  country={"ae"}
                  value={formData.recipientPhone}
                  onChange={handleRecipientPhoneChange}
                  enableSearch={true}
                  disableSearchIcon={true}
                  inputProps={{
                    required: true,
                    name: "recipientPhone",
                  }}
                />
              </div>
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="recipientEmail" className="block mb-1">
                عنوان بريد المستلم الإلكتروني
              </label>
              <input
                type="email"
                name="recipientEmail"
                id="recipientEmail"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.recipientEmail}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-4 max-md:col-span-4">
              <label htmlFor="recipientAddress" className="block mb-1">
                عنوان المستلم
              </label>
              <input
                type="text"
                name="recipientAddress"
                id="recipientAddress"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                value={formData.recipientAddress}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="col-span-4 grid grid-cols-3 gap-4">
              <div className="max-md:col-span-4">
                <label htmlFor="recipientCity" className="block mb-1">
                  مدينة المستلم
                </label>
                <input
                  type="text"
                  name="recipientCity"
                  id="recipientCity"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={formData.recipientCity}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="max-md:col-span-4">
                <label htmlFor="recipientZipCode" className="block mb-1">
                  الرمز البريدي للمستلم
                </label>
                <input
                  type="text"
                  name="recipientZipCode"
                  id="recipientZipCode"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={formData.recipientZipCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="max-md:col-span-4">
                <label htmlFor="recipientCountry" className="block mb-1">
                  بلد المستلم
                </label>
                <select
                  name="recipientCountry"
                  id="recipientCountry"
                  value={formData.recipientCountry}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                >
                  <option value="">--اختر--</option>
                  <option value="af">Afghanistan</option>
                  <option value="al">Albania</option>
                  <option value="dz">Algeria</option>
                  <option value="as">American Samoa</option>
                  <option value="ad">Andorra</option>
                  <option value="ao">Angola</option>
                  <option value="ai">Anguilla</option>
                  <option value="ag">Antigua and Barbuda</option>
                  <option value="ar">Argentina</option>
                  <option value="am">Armenia</option>
                  <option value="aw">Aruba</option>
                  <option value="au">Australia</option>
                  <option value="at">Austria</option>
                  <option value="az">Azerbaijan</option>
                  <option value="bs">Bahamas</option>
                  <option value="bh">Bahrain</option>
                  <option value="bd">Bangladesh</option>
                  <option value="bb">Barbados</option>
                  <option value="by">Belarus</option>
                  <option value="be">Belgium</option>
                  <option value="bz">Belize</option>
                  <option value="bj">Benin</option>
                  <option value="bm">Bermuda</option>
                  <option value="bt">Bhutan</option>
                  <option value="bo">Bolivia</option>
                  <option value="ba">Bosnia and Herzegovina</option>
                  <option value="bw">Botswana</option>
                  <option value="br">Brazil</option>
                  <option value="io">British Indian Ocean Territory</option>
                  <option value="vg">British Virgin Islands</option>
                  <option value="bn">Brunei Darussalam</option>
                  <option value="bg">Bulgaria</option>
                  <option value="bf">Burkina Faso</option>
                  <option value="bi">Burundi</option>
                  <option value="kh">Cambodia</option>
                  <option value="cm">Cameroon</option>
                  <option value="ca">Canada</option>
                  <option value="cv">Cape Verde</option>
                  <option value="ky">Cayman Islands</option>
                  <option value="cf">Central African Rep</option>
                  <option value="td">Chad</option>
                  <option value="cl">Chile</option>
                  <option value="cn">China</option>
                  <option value="cx">Christmas Island</option>
                  <option value="cc">Cocos (Keeling) Islands</option>
                  <option value="co">Colombia</option>
                  <option value="km">Comoros</option>
                  <option value="cd">Congo, Democratic Rep of</option>
                  <option value="cg">Congo, Republic of</option>
                  <option value="ck">Cook Islands</option>
                  <option value="cr">Costa Rica</option>
                  <option value="ci">Cote d'Ivoire</option>
                  <option value="hr">Croatia</option>
                  <option value="cy">Cyprus</option>
                  <option value="cz">Czech Rep</option>
                  <option value="dk">Denmark</option>
                  <option value="dj">Djibouti</option>
                  <option value="dm">Dominica</option>
                  <option value="do">Dominican Rep</option>
                  <option value="ec">Ecuador</option>
                  <option value="eg">Egypt</option>
                  <option value="sv">El Salvador</option>
                  <option value="gq">Equatorial Guinea</option>
                  <option value="er">Eritrea</option>
                  <option value="ee">Estonia</option>
                  <option value="et">Ethiopia</option>
                  <option value="fk">Falkland Islands</option>
                  <option value="fo">Faroe Islands</option>
                  <option value="fj">Fiji</option>
                  <option value="fi">Finland</option>
                  <option value="fr">France</option>
                  <option value="gf">French Guyana</option>
                  <option value="pf">French Polynesia</option>
                  <option value="ga">Gabon</option>
                  <option value="gm">Gambia</option>
                  <option value="ge">Georgia</option>
                  <option value="de">Germany</option>
                  <option value="gh">Ghana</option>
                  <option value="gi">Gibraltar</option>
                  <option value="gr">Greece</option>
                  <option value="gl">Greenland</option>
                  <option value="gd">Grenada</option>
                  <option value="gp">Guadeloupe</option>
                  <option value="gu">Guam</option>
                  <option value="gt">Guatemala</option>
                  <option value="gn">Guinea</option>
                  <option value="gw">Guinea-Bissau</option>
                  <option value="gy">Guyana</option>
                  <option value="ht">Haiti</option>
                  <option value="hn">Honduras</option>
                  <option value="hk">Hong Kong</option>
                  <option value="hu">Hungary</option>
                  <option value="is">Iceland</option>
                  <option value="in">India</option>
                  <option value="id">Indonesia</option>
                  <option value="iq">Iraq</option>
                  <option value="ie">Ireland</option>
                  <option value="il">Israel</option>
                  <option value="it">Italy</option>
                  <option value="jm">Jamaica</option>
                  <option value="jp">Japan</option>
                  <option value="jo">Jordan</option>
                  <option value="kz">Kazakhstan</option>
                  <option value="ke">Kenya</option>
                  <option value="ki">Kiribati</option>
                  <option value="kr">Korea, South</option>
                  <option value="kw">Kuwait</option>
                  <option value="kg">Kyrgyzstan</option>
                  <option value="la">Laos</option>
                  <option value="lv">Latvia</option>
                  <option value="lb">Lebanon</option>
                  <option value="ls">Lesotho</option>
                  <option value="lr">Liberia</option>
                  <option value="ly">Libya</option>
                  <option value="li">Liechtenstein</option>
                  <option value="lt">Lithuania</option>
                  <option value="lu">Luxembourg</option>
                  <option value="mo">Macau</option>
                  <option value="mk">Macedonia</option>
                  <option value="mg">Madagascar</option>
                  <option value="mw">Malawi</option>
                  <option value="my">Malaysia</option>
                  <option value="mv">Maldives</option>
                  <option value="ml">Mali</option>
                  <option value="mt">Malta</option>
                  <option value="mh">Marshall Islands</option>
                  <option value="mq">Martinique</option>
                  <option value="mr">Mauritania</option>
                  <option value="mu">Mauritius</option>
                  <option value="yt">Mayotte</option>
                  <option value="mx">Mexico</option>
                  <option value="fm">Micronesia</option>
                  <option value="md">Moldova, Rep of</option>
                  <option value="mc">Monaco</option>
                  <option value="mn">Mongolia</option>
                  <option value="me">Montenegro</option>
                  <option value="ms">Montserrat</option>
                  <option value="ma">Morocco</option>
                  <option value="mz">Mozambique</option>
                  <option value="mm">Myanmar</option>
                  <option value="na">Namibia</option>
                  <option value="nr">Nauru</option>
                  <option value="np">Nepal</option>
                  <option value="nl">Netherlands</option>
                  <option value="an">Netherlands Antilles</option>
                  <option value="nc">New Caledonia</option>
                  <option value="nz">New Zealand</option>
                  <option value="ni">Nicaragua</option>
                  <option value="ne">Niger</option>
                  <option value="ng">Nigeria</option>
                  <option value="nu">Niue</option>
                  <option value="nf">Norfolk Island</option>
                  <option value="mp">Northern Mariana Islands</option>
                  <option value="no">Norway</option>
                  <option value="om">Oman</option>
                  <option value="pk">Pakistan</option>
                  <option value="pw">Palau</option>
                  <option value="ps">Palestinian Territory, Occupied</option>
                  <option value="pa">Panama</option>
                  <option value="pg">Papua New Guinea</option>
                  <option value="py">Paraguay</option>
                  <option value="pe">Peru</option>
                  <option value="ph">Philippines</option>
                  <option value="pn">Pitcairn Islands</option>
                  <option value="pl">Poland</option>
                  <option value="pt">Portugal</option>
                  <option value="pr">Puerto Rico</option>
                  <option value="qa">Qatar</option>
                  <option value="re">Reunion</option>
                  <option value="ro">Romania</option>
                  <option value="rw">Rwanda</option>
                  <option value="ws">Samoa</option>
                  <option value="sm">San Marino</option>
                  <option value="st">Sao Tome and Principe</option>
                  <option value="sa">Saudi Arabia</option>
                  <option value="sn">Senegal</option>
                  <option value="rs">Serbia</option>
                  <option value="sc">Seychelles</option>
                  <option value="sl">Sierra Leone</option>
                  <option value="sg">Singapore</option>
                  <option value="sk">Slovakia</option>
                  <option value="si">Slovenia</option>
                  <option value="sb">Solomon Islands</option>
                  <option value="so">Somalia</option>
                  <option value="za">South Africa</option>
                  <option value="es">Spain</option>
                  <option value="lk">Sri Lanka</option>
                  <option value="sh">St Helena</option>
                  <option value="kn">St Kitts and Nevis</option>
                  <option value="lc">St Lucia</option>
                  <option value="pm">St Pierre and Miquelon</option>
                  <option value="vc">St Vincent and Grenadines</option>
                  <option value="sr">Suriname</option>
                  <option value="sz">Swaziland</option>
                  <option value="se">Sweden</option>
                  <option value="ch">Switzerland</option>
                  <option value="tw">Taiwan, Rep of China</option>
                  <option value="tj">Tajikistan</option>
                  <option value="tz">Tanzania</option>
                  <option value="th">Thailand</option>
                  <option value="tl">Timor-Leste</option>
                  <option value="tg">Togo</option>
                  <option value="tk">Tokelau</option>
                  <option value="to">Tonga</option>
                  <option value="tt">Trinidad and Tobago</option>
                  <option value="tn">Tunisia</option>
                  <option value="tr">Turkey</option>
                  <option value="tm">Turkmenistan</option>
                  <option value="tc">Turks and Caicos Islands</option>
                  <option value="tv">Tuvalu</option>
                  <option value="ug">Uganda</option>
                  <option value="ae">United Arab Emirates</option>
                  <option value="gb">United Kingdom</option>
                  <option value="vi">United States Virgin Islands</option>
                  <option value="us">United States of America</option>
                  <option value="uy">Uruguay</option>
                  <option value="uz">Uzbekistan</option>
                  <option value="vu">Vanuatu</option>
                  <option value="va">Vatican City</option>
                  <option value="ve">Venezuela</option>
                  <option value="vn">Viet Nam</option>
                  <option value="wf">Wallis and Futuna Islands</option>
                  <option value="eh">Western Sahara</option>
                  <option value="zm">Zambia</option>
                  <option value="zw">Zimbabwe</option>
                  <option value="ss">South Sudan</option>
                </select>
              </div>
            </div>
          </div>

          {/* عنوان الفاتورة */}
          <div className="pt-8 border-b pb-8 max-md:text-sm">
            <h4 className="text-lg font-semibold mb-6 col-span-4">
              عنوان الفاتورة
            </h4>
            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id="Same"
                  type="radio"
                  name="billingAddress"
                  value="same"
                  className="hidden"
                  checked={address === "same"}
                  onChange={handleRadioChange}
                  required
                />
                <label
                  htmlFor="Same"
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  نفس العنوان
                </label>
              </div>

              <div className="flex items-center">
                <input
                  id="New"
                  type="radio"
                  name="billingAddress"
                  value="new"
                  className="hidden"
                  checked={address === "new"}
                  onChange={handleRadioChange}
                  required
                />
                <label
                  htmlFor="New"
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  عنوان جديد
                </label>
              </div>

              {address === "new" && (
                <div className="mt-2 w-full">
                  <input
                    type="text"
                    placeholder="أدخل عنوانًا جديدًا"
                    className="block w-full pl-10 py-5 text-sm text-gray-700 border border-gray-300 rounded-md"
                    value={formData.billingAddress}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: "billingAddress",
                          value: e.target.value,
                        },
                      })
                    }
                    required
                  />
                </div>
              )}
            </div>
          </div>

          {/* كيف تريد تقديم الهدية؟ */}
          <div className="pt-8 border-b pb-8 max-md:text-sm">
            <h4 className="text-lg font-semibold mb-6 col-span-4">
              كيف تريد تقديم الهدية؟
            </h4>
            <div className="text-center flex flex-wrap justify-end gap-5">
              <div className="flex items-center w-full">
                <input
                  id="giftrecipient"
                  type="radio"
                  name="howToSend"
                  value="send to Recipient"
                  className="hidden"
                  onChange={handleInputChange}
                  required
                />
                <label
                  htmlFor="giftrecipient"
                  className="flex items-center cursor-pointer text-right w-full"
                >
                  <span className="w-[20px] h-[20px] inline-block ml-1 rounded-full border border-grey"></span>
                  <div className="w-10/12 max-md:pl-3">
                    أريد إرسال بطاقة الهدية إلى المستلم عبر البريد الإلكتروني
                  </div>
                </label>
              </div>

              <div className="flex items-center w-full">
                <input
                  id="giftclient"
                  type="radio"
                  name="howToSend"
                  value="send to client"
                  className="hidden"
                  onChange={handleInputChange}
                  required
                />
                <label
                  htmlFor="giftclient"
                  className="flex items-center cursor-pointer text-right w-full"
                >
                  <span className="w-[20px] h-[20px] inline-block ml-1 rounded-full border border-grey"></span>
                  <div className="w-10/12 max-md:pl-3">
                    أريد إرسال بطاقة الهدية لي عبر البريد الإلكتروني
                  </div>
                </label>
              </div>

              <div className="flex items-center w-full">
                <input
                  id="giftphysical"
                  type="radio"
                  name="howToSend"
                  value="Send physically"
                  className="hidden"
                  onChange={handleInputChange}
                  required
                />
                <label
                  htmlFor="giftphysical"
                  className="flex items-center cursor-pointer text-right w-full"
                >
                  <span className="w-[20px] h-[20px] inline-block ml-1 rounded-full border border-grey"></span>
                  <div className="w-10/12 max-md:pl-3">
                    أريد إرسال بطاقة الهدية إلى المستلم عبر البريد الإلكتروني
                  </div>
                </label>
              </div>
            </div>
          </div>

          {/* زر الإرسال */}
          <button
            type="submit"
            className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
          >
            {loading ? "جاري التحميل..." : "الدفع"}
          </button>
        </form>
        {/* FORM START */}
        <div className="w-1/3 max-lg:w-full max-lg:pb-8">
          <h3 className="text-4xl font-bold pb-2 max-md:text-2xl text-right">
            أرسل هدية <span className=" text-custom-primary">الآن</span>
          </h3>
          <p className="text-base max-md:text-sm text-right">
            هدية بفرص إنقاذ الحياة
          </p>
        </div>
      </div>
      {/* FORM END */}
    </ParentContainer>
  );
};

export default Form;
