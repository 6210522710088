import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
} from "react-router-dom";

const MobileNav = ({ onMessageUpdate, open }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();

  const handleClickMobile = () => {
    onMessageUpdate(false);
  };

  useEffect(() => {
    // Reset state when the location changes
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  }, [location]);
  return (
    <div className=" min-h-screen overflow-y-auto w-full bg-white flex items-center justify-start fixed top-0 pl-6">
      <div className="flex  ">
        <ul className="flex flex-col w-full  justify-between content-center gap-3  text-xl">
          <li>
            <a href="/about" className="font-semibold">
              About CellSave
            </a>
          </li>
          <li className="relative flex max-lg:flex-wrap content-center justify-start  gap-2 	">
            <div className="flex  gap-2 justify-center">
              <button
                onClick={() => setIsDropdownOpen((prev) => !prev)}
                className={`flex items-center justify-start gap-2 w-full font-semibold ${
                  isDropdownOpen ? "underline" : ""
                } `}
              >
                Discover
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isDropdownOpen ? (
                    <path
                      d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  ) : (
                    <path
                      d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )}
                </svg>
              </button>
            </div>
            {isDropdownOpen && (
              <div className=" top-full -left-1/2  py-2 text-left   rounded-lg z-10 w-full p-4">
                <a
                  href="/stem-cells-sceince"
                  className={`   block px-4 py-2  hover:bg-gray-200`}
                >
                  Stem Cells Science
                </a>
                <a
                  href="/regenerative-medicine"
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  Regenerative Medicine
                </a>
                <div className="flex items-center justify-start  gap-2 w-full">
                  <button className="  flex items-center justify-start relative   hover:bg-gray-200 w-auto">
                    <a
                      href="/sources-of-stem-cell"
                      className={`  ${
                        isDropdownOpen3 ? "underline" : ""
                      } block px-4 py-2  hover:bg-gray-200`}
                    >
                      Sources of Stem Cells
                    </a>
                  </button>
                  <svg
                    className="cursor-pointer   w-8"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsDropdownOpen3((prev) => !prev)}
                  >
                    {isDropdownOpen3 ? (
                      <path
                        d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    ) : (
                      <path
                        d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    )}
                  </svg>
                </div>
                {isDropdownOpen3 && (
                  <div className=" bg-white py-2 w-full rounded-lg z-10 px-4">
                    <a
                      href="/cord-blood"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Blood{" "}
                    </a>
                    <a
                      href="/cord-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Tissue
                    </a>
                    <a
                      href="/placenta-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Placenta Tissue
                    </a>
                    <a
                      href="/cord-vessel"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Vessel
                    </a>
                    <a
                      href="/amnion-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Amnion Tissue
                    </a>
                  </div>
                )}
                <a href="/faqs" className="block px-4 py-2 hover:bg-gray-200">
                  FAQs
                </a>
                <a href="/blog" className="block px-4 py-2 hover:bg-gray-200">
                  Resources
                </a>
              </div>
            )}
          </li>
          <li className="relative flex flex-wrap justify-start content-center  gap-2">
            <button
              onClick={() => setIsDropdownOpen2((prev) => !prev)}
              className={`flex items-center gap-2 font-semibold	${
                isDropdownOpen2 ? "underline" : ""
              }`}
            >
              Services & Pricing
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isDropdownOpen2 ? (
                  <path
                    d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                ) : (
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </button>

            {isDropdownOpen2 && (
              <div className=" bg-white py-2 w-full justify-start text-left rounded-lg z-10">
                <a
                  href="/service-pricing"
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  Service & Pricing
                </a>
                <a
                  href="/gift-registry"
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  Gift Registry
                </a>
              </div>
            )}
          </li>

          <li className="relative flex flex-wrap justify-start content-center   font-semibold">
            <a href="/contact" className="block  ">
              Contact
            </a>
          </li>
          <li className="relative flex flex-wrap justify-start content-center gap-2">
            {localStorage.getItem("jwt") ? (
              // Render something if JWT is present
              <a href="/profile/order-history">Account</a> // Placeholder; replace with appropriate content for logged-in state
            ) : (
              <button
                onClick={() => open(true)}
                className={`flex items-center gap-2 font-semibold ${
                  isDropdownOpen2 ? "underline" : ""
                }`}
              >
                Log In
              </button>
            )}
          </li>
          <li className="relative flex flex-wrap justify-start content-center gap-2">
            <a
              href="/checkout"
              className="bg-gradient-to-br from-custom-primary to-custom-secondary text-sm text-white px-5 py-2 rounded-3xl font-semibold"
            >
              Order Now
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
