import React, { useState, useEffect, useContext } from "react";
import assets from "../assets/assets";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MobileNav from "./MobileNav";
import ParentContainer from "./ParentContainer";
import LoginPopup from "../pages/LoginPopup";
import LoginPopupAR from "../pages-ar/LoginPopup";

import SearchComponent from "../pages/SearchComponent";
import Popup from "../pages/Popup";
import { ApiUrl } from "./ApiUrl";
import axios from "axios";
import { LangContext } from "./LangContext";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isMobileNav, setIsMobileNav] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();
  const [login, setLogin] = useState();
  const [langNow, setLangNow] = useState(localStorage.getItem("lang") || "en");
  const [isVisible, setIsVisible] = useState(false);
  const [langNowURL, setLangNowURL] = useState();
  const navigate = useNavigate();

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen(true);
  };
  const handleMouseEnter2 = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen2(true);
  };
  const handleMouseEnter3 = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen3(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 10);
    setTimeoutId(id);
  };
  // Function to handle mouse leave event
  const handleMouseLeave2 = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen2(false);
    }, 10);
    setTimeoutId(id);
  };
  const handleMouseLeave3 = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen3(false);
    }, 10);
    setTimeoutId(id);
  };

  const updateMobileNav = (newState) => {
    setIsMobileNav(newState);
  };
  // Function to clear timeout when unmounting component
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // Reset state when the location changes
    // Disable scrolling when mobile nav is active
    document.body.style.overflow = isMobileNav ? "hidden" : "auto";

    // Reset state when the location changes
    setIsMobileNav(false);
  }, [location]);

  useEffect(() => {
    // Reset state when the location changes
    // Disable scrolling when mobile nav is active
    document.body.style.overflow = isMobileNav ? "hidden" : "auto";

    // Reset state when the location changes
  }, [isMobileNav]);

  const [promo, setPromo] = useState(null);
  const [loading, setLoading] = useState(true);

  // Sync state with localStorage on mount
  useEffect(() => {
    const storedLang = localStorage.getItem("lang") || "en";
    if (storedLang !== langNow) {
      setLangNow(storedLang);
    }

    if (location.pathname.startsWith("/ar")) {
      setLangNowURL("ar");
      const fetchHomePageDataAR = async () => {
        try {
          const response = await axios.get(
            `${ApiUrl}/api/header-promo?populate=*&locale=ar`
          );
          setPromo(response.data);
          console.log("header", response.data);
        } catch (error) {
          console.error("Error fetching home page data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchHomePageDataAR();
    } else {
      setLangNowURL("en");
      const fetchHomePageData = async () => {
        try {
          const response = await axios.get(
            `${ApiUrl}/api/header-promo?populate=*`
          );
          setPromo(response.data);
          console.log("header", response.data);
        } catch (error) {
          console.error("Error fetching home page data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchHomePageData();
    }
  }, []);

  // Fetch data when langNow changes
  useEffect(() => {}, []); // Fetch when langNow updates

  const switchLanguage = () => {
    if (location.pathname.startsWith("/ar")) {
      if (location.pathname.includes("/ar/blog-single")) {
        navigate("/blog"); // navigate to the English path
      } else if (location.pathname.includes("/ar/featured-moms-single")) {
        navigate("/featured-moms"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/6")) {
        navigate("/product/2"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/5")) {
        navigate("/product/3"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/4")) {
        navigate("/product/1"); // navigate to the English path
      } else {
        const newPath = location.pathname.replace("/ar", "") || "/";

        navigate(newPath); // navigate to the English path
      }
      setLangNow("en");
      window.dispatchEvent(new Event("languageChange"));

      // Force full page reload:
      window.location.reload();
    } else {
      if (location.pathname.includes("/blog-single")) {
        navigate("/ar/blog"); // navigate to the English path
      } else if (location.pathname.includes("/featured-moms-single")) {
        navigate("/ar/featured-moms"); // navigate to the English path
      } else if (location.pathname.includes("/product/2")) {
        navigate("/ar/product/6"); // navigate to the English path
      } else if (location.pathname.includes("/product/3")) {
        navigate("/ar/product/5"); // navigate to the English path
      } else if (location.pathname.includes("/product/1")) {
        navigate("/ar/product/4"); // navigate to the English path
      } else {
        const newPath = "/ar" + location.pathname;
        navigate(newPath); // navigate to the Arabic path
      }

      setLangNow("ar");
      window.dispatchEvent(new Event("languageChange"));

      // Force full page reload:
      window.location.reload();
    }
  };

  return (
    <>
      {" "}
      <Popup setIsVisible={setIsVisible} isVisible={isVisible} promo={promo} />
      {login &&
        (langNowURL === "ar" ? (
          <LoginPopupAR close={setLogin} />
        ) : (
          <LoginPopup close={setLogin} />
        ))}
      <header
        dir={langNowURL == "ar" ? "rtl" : ""}
        className="sticky top-0 bg-white shadow-md z-[999]"
      >
        <ParentContainer
          containerHeight={"py-0"}
          containerStyle={
            "flex max-lg:hidden  py-2 justify-between  w-full  bg-custom-primary text-white"
          }
        >
          <div onClick={() => setIsVisible(true)} className="cursor-pointer">
            {promo && promo.data.attributes.text}
          </div>
          <h3 className="">
            <a href="tel:009718002796">
              {" "}
              {langNowURL == "ar"
                ? "احصل على الدعم: ‎+971 800 2796"
                : "Get Support: +971 (800) 2796"}
            </a>
          </h3>

          <div className="flex justify-end content-center">
            <ul className="flex justify-around w-atuo gap-4 px-8 ">
              <li>
                <a href={langNowURL == "ar" ? "/ar/blog" : "/blog"}>
                  {langNowURL == "ar" ? "الموارد" : "Resources"}
                </a>
              </li>
              <li>
                <a href={langNowURL == "ar" ? "/ar/faqs" : "/faqs"}>
                  {langNowURL == "ar" ? "الأسئلة الشائعة" : "FAQs"}
                </a>
              </li>
              <li>
                <a href={langNowURL == "ar" ? "/ar/contact" : "/contact"}>
                  {langNowURL == "ar" ? "اتصل بنا" : "Contact Us"}
                </a>
              </li>
              <li>
                <div
                  className="font-semibold cursor-pointer "
                  onClick={() => switchLanguage()}
                >
                  {location.pathname.startsWith("/ar") ? "English" : "عربي"}
                </div>
              </li>
            </ul>
          </div>
        </ParentContainer>
        <ParentContainer
          containerStyle={
            "flex w-full justify-end bg-white px-20  relative z-[999]"
          }
          containerHeight={"py-0"}
        >
          <div className=" w-[30%] max-lg:w-1/2 py-3">
            <a href={langNowURL == "ar" ? "/ar" : "/"}>
              <img src={assets.logosvg} alt="CellsaveLogo" />
            </a>
          </div>
          <div className="w-[40%] justify-center content-center items-center flex max-lg:hidden ">
            <ul className="flex justify-between content-center gap-8">
              <li>
                <a
                  href={langNowURL == "ar" ? "/ar/about" : "/about"}
                  className="font-semibold"
                >
                  {langNowURL == "ar" ? "حول سيل سيف" : "About CellSave"}
                </a>
              </li>
              <li className="relative flex content-center items-center gap-2 font-semibold">
                <button
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="flex items-center gap-2 w-full"
                >
                  {langNowURL == "ar" ? "استكشف" : "Discover"}
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {isDropdownOpen && (
                    <div
                      className="absolute top-full -left-1/2 bg-white shadow-lg py-2 w-56 rounded-lg z-10"
                      onMouseLeave={handleMouseLeave}
                    >
                      <a
                        href={
                          langNowURL == "ar"
                            ? "/ar/stem-cells-sceince"
                            : "/stem-cells-sceince"
                        }
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar"
                          ? "علم الخلايا الجذعية"
                          : "Stem Cells Science"}
                      </a>
                      <a
                        href={
                          langNowURL == "ar"
                            ? "/ar/regenerative-medicine"
                            : "/regenerative-medicine"
                        }
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar"
                          ? "الطب التجديدي"
                          : "Regenerative Medicine"}
                      </a>
                      <button
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                        className="flex items-center justify-center relative w-full text-gray-800 hover:bg-gray-200"
                      >
                        <a
                          href={
                            langNowURL == "ar"
                              ? "/ar/sources-of-stem-cell"
                              : "/sources-of-stem-cell"
                          }
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          {langNowURL == "ar"
                            ? "مصادر الخلايا الجذعية"
                            : "Sources of Stem Cells"}
                        </a>
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.11719 8.48242L4.72902 5.04644L1.3033 1.42488"
                            stroke="#37424A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        {isDropdownOpen3 && (
                          <div
                            className="absolute left-full -top-0 bg-white shadow-lg py-2 w-56 rounded-lg z-10"
                            onMouseLeave={handleMouseLeave3}
                          >
                            <a
                              href={
                                langNowURL == "ar"
                                  ? "/ar/cord-blood"
                                  : "/cord-blood"
                              }
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {langNowURL == "ar"
                                ? "دم الحبل السري"
                                : "Cord Blood"}
                            </a>
                            <a
                              href={
                                langNowURL == "ar"
                                  ? "/ar/cord-tissue"
                                  : "/cord-tissue"
                              }
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {langNowURL == "ar"
                                ? "نسيج الحبل السري"
                                : "Cord Tissue"}
                            </a>
                            <a
                              href={
                                langNowURL == "ar"
                                  ? "/ar/placenta-tissue"
                                  : "/placenta-tissue"
                              }
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {langNowURL == "ar"
                                ? "نسيج المشيمة"
                                : "Placenta Tissue"}
                            </a>
                            <a
                              href={
                                langNowURL == "ar"
                                  ? "/ar/cord-vessel"
                                  : "/cord-vessel"
                              }
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {langNowURL == "ar"
                                ? "أوعية الحبل السري"
                                : "Cord Vessel"}
                            </a>
                            <a
                              href={
                                langNowURL == "ar"
                                  ? "/ar/amnion-tissue"
                                  : "/amnion-tissue"
                              }
                              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                            >
                              {langNowURL == "ar"
                                ? "النسج الأمنيوسي"
                                : "Amnion Tissue"}
                            </a>
                          </div>
                        )}
                      </button>
                      <a
                        href={langNowURL == "ar" ? "/ar/faqs" : "/faqs"}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar" ? "الأسئلة الشائعة" : "FAQs"}
                      </a>
                      <a
                        href={langNowURL == "ar" ? "/ar/blog" : "/blog"}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar" ? "الموارد" : "Resources"}
                      </a>
                    </div>
                  )}
                </button>
              </li>
              <li className="relative flex content-center items-center gap-2">
                <button
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  className="flex items-center gap-2 font-semibold"
                >
                  {langNowURL == "ar"
                    ? "الخدمات والأسعار"
                    : "Services & Pricing"}
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {isDropdownOpen2 && (
                    <div
                      className="absolute top-full bg-white shadow-lg py-2 w-56 rounded-lg z-10"
                      onMouseLeave={handleMouseLeave2}
                    >
                      <a
                        href={
                          langNowURL == "ar"
                            ? "/ar/service-pricing"
                            : "/service-pricing"
                        }
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar"
                          ? "الخدمات والأسعار"
                          : "Service & Pricing"}
                      </a>
                      <a
                        href={
                          langNowURL == "ar"
                            ? "/ar/gift-registry"
                            : "/gift-registry"
                        }
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {langNowURL == "ar" ? "سجل الهدايا" : "Gift Registry"}
                      </a>
                    </div>
                  )}
                </button>
              </li>
            </ul>
          </div>

          <div className="w-[30%] max-lg:w-1/2 md:px-10 flex items-center justify-end">
            <div className=" w-1/2 flex  items-center gap-3 max-lg:hidden  ">
              <div onClick={() => setIsSearch(true)} href="#">
                <svg
                  width="35"
                  height="36"
                  viewBox="0 0 35 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.9749 26.9497C22.4838 26.9497 26.9497 22.4838 26.9497 16.9749C26.9497 11.4659 22.4838 7 16.9749 7C11.4659 7 7 11.4659 7 16.9749C7 22.4838 11.4659 26.9497 16.9749 26.9497Z"
                    stroke="url(#paint0_linear_590_3705)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.0004 28.0004L25.9004 25.9004"
                    stroke="url(#paint1_linear_590_3705)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_590_3705"
                      x1="5.10822"
                      y1="9.21664"
                      x2="30.4259"
                      y2="11.0041"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8884D5" />
                      <stop offset="1" stop-color="#CF0072" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_590_3705"
                      x1="25.7013"
                      y1="26.1337"
                      x2="28.3663"
                      y2="26.3219"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8884D5" />
                      <stop offset="1" stop-color="#CF0072" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              {localStorage.getItem("jwt") != null ? (
                <a
                  href={
                    langNowURL == "ar"
                      ? "/ar/profile/order-history"
                      : "/profile/order-history"
                  }
                  className="cursor-pointer"
                >
                  {" "}
                  <svg
                    width="35"
                    height="36"
                    viewBox="0 0 35 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.6306 18.8449C17.5548 18.834 17.4573 18.834 17.3706 18.8449C15.4639 18.7799 13.9473 17.2199 13.9473 15.3024C13.9473 13.3415 15.5289 11.749 17.5006 11.749C19.4614 11.749 21.0539 13.3415 21.0539 15.3024C21.0431 17.2199 19.5373 18.7799 17.6306 18.8449Z"
                      stroke="url(#paint0_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.8026 25.9954C22.8742 27.7613 20.3176 28.8338 17.5009 28.8338C14.6842 28.8338 12.1276 27.7613 10.1992 25.9954C10.3076 24.9771 10.9576 23.9804 12.1167 23.2004C15.0851 21.2288 19.9384 21.2288 22.8851 23.2004C24.0442 23.9804 24.6942 24.9771 24.8026 25.9954Z"
                      stroke="url(#paint1_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4993 28.8337C23.4824 28.8337 28.3327 23.9834 28.3327 18.0003C28.3327 12.0172 23.4824 7.16699 17.4993 7.16699C11.5163 7.16699 6.66602 12.0172 6.66602 18.0003C6.66602 23.9834 11.5163 28.8337 17.4993 28.8337Z"
                      stroke="url(#paint2_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_590_3706"
                        x1="13.2734"
                        y1="12.5374"
                        x2="22.2921"
                        y2="13.1751"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_590_3706"
                        x1="8.81442"
                        y1="22.5119"
                        x2="27.0562"
                        y2="25.1563"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_590_3706"
                        x1="4.61142"
                        y1="9.5744"
                        x2="32.108"
                        y2="11.5157"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
              ) : (
                <div className=" cursor-pointer" onClick={() => setLogin(true)}>
                  <svg
                    width="35"
                    height="36"
                    viewBox="0 0 35 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.6306 18.8449C17.5548 18.834 17.4573 18.834 17.3706 18.8449C15.4639 18.7799 13.9473 17.2199 13.9473 15.3024C13.9473 13.3415 15.5289 11.749 17.5006 11.749C19.4614 11.749 21.0539 13.3415 21.0539 15.3024C21.0431 17.2199 19.5373 18.7799 17.6306 18.8449Z"
                      stroke="url(#paint0_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.8026 25.9954C22.8742 27.7613 20.3176 28.8338 17.5009 28.8338C14.6842 28.8338 12.1276 27.7613 10.1992 25.9954C10.3076 24.9771 10.9576 23.9804 12.1167 23.2004C15.0851 21.2288 19.9384 21.2288 22.8851 23.2004C24.0442 23.9804 24.6942 24.9771 24.8026 25.9954Z"
                      stroke="url(#paint1_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4993 28.8337C23.4824 28.8337 28.3327 23.9834 28.3327 18.0003C28.3327 12.0172 23.4824 7.16699 17.4993 7.16699C11.5163 7.16699 6.66602 12.0172 6.66602 18.0003C6.66602 23.9834 11.5163 28.8337 17.4993 28.8337Z"
                      stroke="url(#paint2_linear_590_3706)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_590_3706"
                        x1="13.2734"
                        y1="12.5374"
                        x2="22.2921"
                        y2="13.1751"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_590_3706"
                        x1="8.81442"
                        y1="22.5119"
                        x2="27.0562"
                        y2="25.1563"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_590_3706"
                        x1="4.61142"
                        y1="9.5744"
                        x2="32.108"
                        y2="11.5157"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8884D5" />
                        <stop offset="1" stop-color="#CF0072" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              )}
            </div>
            <a
              href={langNowURL == "ar" ? "/ar/checkout" : "/checkout"}
              className="max-lg:hidden bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-3 py-2 rounded-3xl font-semibold"
            >
              {langNowURL === "ar" ? "اطلب الآن" : "Order Now"}
            </a>
            {isMobileNav ? (
              <div
                onClick={() => setIsMobileNav(false)}
                className=" max-lg:block hidden cursor-pointer bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-2 py-2 rounded-lg font-semibold"
              >
                <svg
                  enable-background="new 0 0 24 24"
                  height="24px"
                  id="Слой_1"
                  version="1.1"
                  viewBox="0 0 32 32"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z"
                    fill="white"
                    id="Close"
                  />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                </svg>
              </div>
            ) : (
              <div
                onClick={() => setIsMobileNav(true)}
                className=" max-lg:block hidden cursor-pointer bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-2 py-2 rounded-lg font-semibold"
              >
                {isMobileNav}
                <svg
                  class="feather feather-menu"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
              </div>
            )}
          </div>
        </ParentContainer>
        {isMobileNav ? (
          <MobileNav open={setLogin} onMessageUpdate={updateMobileNav} />
        ) : (
          ""
        )}
        {isSearch ? <SearchComponent setIsSearch={setIsSearch} /> : ""}
      </header>
    </>
  );
};

export default Header;
