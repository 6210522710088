import React, { useEffect, useState } from "react";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";

const NewsListing = ({ data = [] }) => {
  const [lang, setLang] = useState();

  if (!data.length) return null; // Return nothing if data is empty or undefined
  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      setLang("ar");
    } else {
      setLang("en");
    }
  }, []);
  return (
    <ParentContainer containerStyle="flex flex-wrap">
      {data[0] && (
        <a
          href={
            lang === "ar"
              ? `/ar/blog-single/${
                  data[0]?.attributes?.Title?.replaceAll("-", "^")
                    .replaceAll(" ", "-")
                    .replaceAll("?", "&*") || ""
                }`
              : `/blog-single/${
                  data[0]?.attributes?.Title?.replaceAll("-", "^")
                    .replaceAll(" ", "-")
                    .replaceAll("?", "&*") || ""
                }`
          }
          className="w-1/2 flex flex-col gap-3 max-lg:w-full"
        >
          {data[0]?.attributes?.Image?.data?.attributes?.url && (
            <img
              src={ApiUrl + data[0]?.attributes?.Image?.data?.attributes?.url}
              alt={
                data[0]?.attributes?.Image?.data?.attributes?.alternativeText ||
                ""
              }
              className="object-cover h-[300px] rounded-lg"
            />
          )}
          <p className="text-xs text-gray-500">
            {data[0]?.attributes?.blog_category?.data?.attributes?.Category ||
              "Uncategorized"}
          </p>
          <h2 className="text-2xl font-semibold underline max-md:text-xl">
            {data[0]?.attributes?.Title || "Untitled"}
          </h2>
        </a>
      )}

      <div className="w-1/2 pr-5 flex flex-col gap-2 max-lg:w-full max-lg:pl-0">
        <div className="border-b border-custom-primary pb-2 max-lg:border-t max-lg:border-b-0 max-lg:mt-4 max-lg:pt-4">
          <h3 className="text-2xl text-custom-primary font-semibold">
            {lang === "ar" ? "أخبار رائجة" : "Top News"}
          </h3>
        </div>

        <div className="flex flex-col">
          {data.slice(1, 5).map((top, index) => (
            <a
              href={
                lang === "ar"
                  ? `/ar/blog-single/${
                      top?.attributes?.Title?.replaceAll("-", "^")
                        .replaceAll(" ", "-")
                        .replaceAll("?", "&*") || ""
                    }`
                  : `/blog-single/${
                      top?.attributes?.Title?.replaceAll("-", "^")
                        .replaceAll(" ", "-")
                        .replaceAll("?", "&*") || ""
                    }`
              }
              className="py-5"
              key={index}
            >
              <p className="text-xs text-gray-500">
                {top?.attributes?.blog_category?.data?.attributes?.Category ||
                  "Uncategorized"}
              </p>
              <h2 className="text-xl font-semibold underline max-md:text-base">
                {top?.attributes?.Title || "Untitled"}
              </h2>
            </a>
          ))}
        </div>
      </div>
    </ParentContainer>
  );
};

export default NewsListing;
