import React, { useEffect, useState } from "react";
import assets from "../../assets/assets";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";

const ThirdStep = ({ handleClickfrom, data, order }) => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
    birthMotherDOB: "",
    expectedDueDate: "",
    referral: "",
    addressInfo: "same", // Default value for radio buttons
    newAddress: {
      address: "",
      city: "",
      zipCode: "",
      country: "",
    },
  });

  const handleChange = (item) => {
    handleClickfrom(item);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      addressInfo: e.target.value,
    }));
  };

  // Step 2: Keep a separate savedData for the five fields
  const [savedData, setSavedData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    date: "",
  });

  // Step 2a: Read from localStorage once
  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setSavedData(JSON.parse(data));
    }
  }, []);

  // Step 2b: Merge savedData into your existing form once it's loaded
  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      const parsedData = JSON.parse(data);
      setSavedData(parsedData);
      setFormData((prevData) => ({
        ...prevData,
        firstName: parsedData.firstName,
        lastName: parsedData.lastName,
        phoneNumber: parsedData.phone,
        email: parsedData.email,
        expectedDueDate: parsedData.date,
      }));
      // Sync phone state
      setPhone(parsedData.phone);
    }
  });

  const handleSubmit = async () => {
    setSubmitted(true); // Mark the form as submitted

    // Check if any required field is empty
    if (
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      !phone.trim() ||
      !formData.email.trim() ||
      !formData.address.trim() ||
      !formData.city.trim() ||
      !formData.zipCode.trim() ||
      !formData.country.trim() ||
      !formData.birthMotherDOB ||
      !formData.expectedDueDate ||
      !formData.referral.trim()
    ) {
      // If any field is empty, stop submission
      console.error("Validation failed: Required fields are missing.");
      return;
    }

    setLoading(true);

    let redirectUrl = null;

    try {
      // Telr API Call
      const telrResponse = await axios.post(`${ApiUrl}/api/total/price`, {
        orderDetails: {
          selectedProduct: order.selectedProduct,
          selectedPayment: order.selectedPayment,
          quantity: order.quantity,
          addon: order.addon,
          couponData: order.couponCode,
        },
        customerData: formData,
      });
      console.log(telrResponse.data);
      redirectUrl = telrResponse.data?.order?.url;
      if (!redirectUrl) {
        throw new Error("Redirect URL not found in Telr response.");
      }

      console.log("Telr data created successfully:", telrResponse.data);
    } catch (error) {
      console.error("Error creating Telr session:", {
        error: error.response?.data || error.message,
      });
    }

    try {
      // Salesforce API Call
      const saleforceData = {
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Email: formData.email,
        MobilePhone: phone,
        Origin_Program__c: "Online Checkout",
        Lead_Quality__c: "Hot",
        AttendingDoctor__c: "a00f400000XCppCAAT",
        Hospital_Clinics__c: "a01f400000KF5VMAA1",
        Program_Running__c: "Website",
        Origin_Hospital__c: "a01f400000KF5VMAA1",
        Origin_Doctor__c: "a00f400000XCppCAAT",
        Due_Date__c: formData.expectedDueDate,
      };

      const saleforceResponse = await axios.post(
        `${ApiUrl}/api/salesforce`,
        saleforceData
      );

      console.log("Salesforce data sent successfully:", saleforceResponse.data);
    } catch (error) {
      console.error("Error sending Salesforce data:", {
        error: error.response?.data || error.message,
      });
    } finally {
      setLoading(false);
    }

    // Redirect only after both API calls are processed
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px] mb-8">
      <div className="p-8 bg-white rounded-lg flex flex-wrap max-md:p-4 max-md:text-sm">
        <div className="w-4/6 max-lg:w-full">
          <div className="flex flex-col gap-4 w-full border-b pb-8">
            <div className="flex items-center gap-2">
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#8884D5" />
                  <path
                    d="M19.08 13.64H13.58V11H23.04V13.32L19.5 16.7C19.98 16.78 20.4467 16.9067 20.9 17.08C21.3533 17.2533 21.7533 17.5 22.1 17.82C22.4467 18.1267 22.7267 18.5133 22.94 18.98C23.1533 19.4333 23.26 19.9867 23.26 20.64C23.26 21.3067 23.14 21.92 22.9 22.48C22.6733 23.04 22.34 23.5267 21.9 23.94C21.4733 24.3533 20.9467 24.6733 20.32 24.9C19.6933 25.1267 18.9933 25.24 18.22 25.24C16.9533 25.24 15.8733 25.02 14.98 24.58C14.1 24.1267 13.36 23.54 12.76 22.82L14.88 20.8C15.36 21.3467 15.86 21.7667 16.38 22.06C16.9133 22.34 17.54 22.48 18.26 22.48C18.8467 22.48 19.32 22.3267 19.68 22.02C20.0533 21.7133 20.24 21.2933 20.24 20.76C20.24 20.1733 20 19.7267 19.52 19.42C19.0533 19.1133 18.38 18.96 17.5 18.96H16.22L15.74 17L19.08 13.64Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-lg font-semibold capitalize">
                معلومات مفصلة أكثر
              </p>
            </div>
            <h4 className="text-lg font-semibold mb-2 capitalize">
              الخطوة 1: معلوماتك
            </h4>
            <div className="pt-2 py-4 grid grid-cols-4 gap-4 w-full">
              <div className="col-span-4 grid grid-cols-2 gap-4">
                <div className="max-md:col-span-4">
                  <label htmlFor="firstName" className="block mb-1">
                    الاسم الأول <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    required
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                  {submitted && !formData.firstName.trim() && (
                    <p className="text-red-500 text-sm mt-1">
                      هذا الحقل مطلوب.
                    </p>
                  )}
                </div>
                <div className="max-md:col-span-4">
                  <label htmlFor="lastName" className="block mb-1">
                    اسم العائلة <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                  {submitted && !formData.lastName.trim() && (
                    <p className="text-red-500 text-sm mt-1">
                      هذا الحقل مطلوب.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-2 max-md:col-span-4">
                <label htmlFor="phoneNumber" className="block mb-1">
                  رقم الهاتف <span className="text-red-500">*</span>
                </label>
                <PhoneInput
                  country={"ae"}
                  value={phone}
                  onChange={(phone) => {
                    setPhone(phone);
                    handleInputChange({
                      target: { name: "phoneNumber", value: phone },
                    });
                  }}
                  enableSearch={true}
                  disableSearchIcon={true}
                  required
                />
                {submitted && !phone.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
              <div className="col-span-2 max-md:col-span-4">
                <label htmlFor="email" className="block mb-1">
                  البريد الإلكتروني <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {submitted && !formData.email.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>

              <div className="col-span-4">
                <label htmlFor="address" className="block mb-1">
                  العنوان <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {submitted && !formData.address.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
            </div>
            <div className="col-span-4 grid grid-cols-3 gap-4">
              <div className="max-md:col-span-3">
                <label htmlFor="city" className="block mb-1">
                  المدينة <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {submitted && !formData.city.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
              <div className="max-md:col-span-3">
                <label htmlFor="zipCode" className="block mb-1">
                  الرمز البريدي <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {submitted && !formData.zipCode.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
              <div className="max-md:col-span-3">
                <label htmlFor="country" className="block mb-1">
                  الدولة <span className="text-red-500">*</span>
                </label>
                <select
                  name="country"
                  id="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                >
                  <option value="">--اختر--</option>
                  <option value="af">Afghanistan</option>
                  <option value="al">Albania</option>
                  <option value="dz">Algeria</option>
                  <option value="as">American Samoa</option>
                  <option value="ad">Andorra</option>
                  <option value="ao">Angola</option>
                  <option value="ai">Anguilla</option>
                  <option value="ag">Antigua and Barbuda</option>
                  <option value="ar">Argentina</option>
                  <option value="am">Armenia</option>
                  <option value="aw">Aruba</option>
                  <option value="au">Australia</option>
                  <option value="at">Austria</option>
                  <option value="az">Azerbaijan</option>
                  <option value="bs">Bahamas</option>
                  <option value="bh">Bahrain</option>
                  <option value="bd">Bangladesh</option>
                  <option value="bb">Barbados</option>
                  <option value="by">Belarus</option>
                  <option value="be">Belgium</option>
                  <option value="bz">Belize</option>
                  <option value="bj">Benin</option>
                  <option value="bm">Bermuda</option>
                  <option value="bt">Bhutan</option>
                  <option value="bo">Bolivia</option>
                  <option value="ba">Bosnia and Herzegovina</option>
                  <option value="bw">Botswana</option>
                  <option value="br">Brazil</option>
                  <option value="io">British Indian Ocean Territory</option>
                  <option value="vg">British Virgin Islands</option>
                  <option value="bn">Brunei Darussalam</option>
                  <option value="bg">Bulgaria</option>
                  <option value="bf">Burkina Faso</option>
                  <option value="bi">Burundi</option>
                  <option value="kh">Cambodia</option>
                  <option value="cm">Cameroon</option>
                  <option value="ca">Canada</option>
                  <option value="cv">Cape Verde</option>
                  <option value="ky">Cayman Islands</option>
                  <option value="cf">Central African Rep</option>
                  <option value="td">Chad</option>
                  <option value="cl">Chile</option>
                  <option value="cn">China</option>
                  <option value="cx">Christmas Island</option>
                  <option value="cc">Cocos (Keeling) Islands</option>
                  <option value="co">Colombia</option>
                  <option value="km">Comoros</option>
                  <option value="cd">Congo, Democratic Rep of</option>
                  <option value="cg">Congo, Republic of</option>
                  <option value="ck">Cook Islands</option>
                  <option value="cr">Costa Rica</option>
                  <option value="ci">Cote d'Ivoire</option>
                  <option value="hr">Croatia</option>
                  <option value="cy">Cyprus</option>
                  <option value="cz">Czech Rep</option>
                  <option value="dk">Denmark</option>
                  <option value="dj">Djibouti</option>
                  <option value="dm">Dominica</option>
                  <option value="do">Dominican Rep</option>
                  <option value="ec">Ecuador</option>
                  <option value="eg">Egypt</option>
                  <option value="sv">El Salvador</option>
                  <option value="gq">Equatorial Guinea</option>
                  <option value="er">Eritrea</option>
                  <option value="ee">Estonia</option>
                  <option value="et">Ethiopia</option>
                  <option value="fk">Falkland Islands</option>
                  <option value="fo">Faroe Islands</option>
                  <option value="fj">Fiji</option>
                  <option value="fi">Finland</option>
                  <option value="fr">France</option>
                  <option value="gf">French Guyana</option>
                  <option value="pf">French Polynesia</option>
                  <option value="ga">Gabon</option>
                  <option value="gm">Gambia</option>
                  <option value="ge">Georgia</option>
                  <option value="de">Germany</option>
                  <option value="gh">Ghana</option>
                  <option value="gi">Gibraltar</option>
                  <option value="gr">Greece</option>
                  <option value="gl">Greenland</option>
                  <option value="gd">Grenada</option>
                  <option value="gp">Guadeloupe</option>
                  <option value="gu">Guam</option>
                  <option value="gt">Guatemala</option>
                  <option value="gn">Guinea</option>
                  <option value="gw">Guinea-Bissau</option>
                  <option value="gy">Guyana</option>
                  <option value="ht">Haiti</option>
                  <option value="hn">Honduras</option>
                  <option value="hk">Hong Kong</option>
                  <option value="hu">Hungary</option>
                  <option value="is">Iceland</option>
                  <option value="in">India</option>
                  <option value="id">Indonesia</option>
                  <option value="iq">Iraq</option>
                  <option value="ie">Ireland</option>
                  <option value="il">Israel</option>
                  <option value="it">Italy</option>
                  <option value="jm">Jamaica</option>
                  <option value="jp">Japan</option>
                  <option value="jo">Jordan</option>
                  <option value="kz">Kazakhstan</option>
                  <option value="ke">Kenya</option>
                  <option value="ki">Kiribati</option>
                  <option value="kr">Korea, South</option>
                  <option value="kw">Kuwait</option>
                  <option value="kg">Kyrgyzstan</option>
                  <option value="la">Laos</option>
                  <option value="lv">Latvia</option>
                  <option value="lb">Lebanon</option>
                  <option value="ls">Lesotho</option>
                  <option value="lr">Liberia</option>
                  <option value="ly">Libya</option>
                  <option value="li">Liechtenstein</option>
                  <option value="lt">Lithuania</option>
                  <option value="lu">Luxembourg</option>
                  <option value="mo">Macau</option>
                  <option value="mk">Macedonia</option>
                  <option value="mg">Madagascar</option>
                  <option value="mw">Malawi</option>
                  <option value="my">Malaysia</option>
                  <option value="mv">Maldives</option>
                  <option value="ml">Mali</option>
                  <option value="mt">Malta</option>
                  <option value="mh">Marshall Islands</option>
                  <option value="mq">Martinique</option>
                  <option value="mr">Mauritania</option>
                  <option value="mu">Mauritius</option>
                  <option value="yt">Mayotte</option>
                  <option value="mx">Mexico</option>
                  <option value="fm">Micronesia</option>
                  <option value="md">Moldova, Rep of</option>
                  <option value="mc">Monaco</option>
                  <option value="mn">Mongolia</option>
                  <option value="me">Montenegro</option>
                  <option value="ms">Montserrat</option>
                  <option value="ma">Morocco</option>
                  <option value="mz">Mozambique</option>
                  <option value="mm">Myanmar</option>
                  <option value="na">Namibia</option>
                  <option value="nr">Nauru</option>
                  <option value="np">Nepal</option>
                  <option value="nl">Netherlands</option>
                  <option value="an">Netherlands Antilles</option>
                  <option value="nc">New Caledonia</option>
                  <option value="nz">New Zealand</option>
                  <option value="ni">Nicaragua</option>
                  <option value="ne">Niger</option>
                  <option value="ng">Nigeria</option>
                  <option value="nu">Niue</option>
                  <option value="nf">Norfolk Island</option>
                  <option value="mp">Northern Mariana Islands</option>
                  <option value="no">Norway</option>
                  <option value="om">Oman</option>
                  <option value="pk">Pakistan</option>
                  <option value="pw">Palau</option>
                  <option value="ps">Palestinian Territory, Occupied</option>
                  <option value="pa">Panama</option>
                  <option value="pg">Papua New Guinea</option>
                  <option value="py">Paraguay</option>
                  <option value="pe">Peru</option>
                  <option value="ph">Philippines</option>
                  <option value="pn">Pitcairn Islands</option>
                  <option value="pl">Poland</option>
                  <option value="pt">Portugal</option>
                  <option value="pr">Puerto Rico</option>
                  <option value="qa">Qatar</option>
                  <option value="re">Reunion</option>
                  <option value="ro">Romania</option>
                  <option value="rw">Rwanda</option>
                  <option value="ws">Samoa</option>
                  <option value="sm">San Marino</option>
                  <option value="st">Sao Tome and Principe</option>
                  <option value="sa">Saudi Arabia</option>
                  <option value="sn">Senegal</option>
                  <option value="rs">Serbia</option>
                  <option value="sc">Seychelles</option>
                  <option value="sl">Sierra Leone</option>
                  <option value="sg">Singapore</option>
                  <option value="sk">Slovakia</option>
                  <option value="si">Slovenia</option>
                  <option value="sb">Solomon Islands</option>
                  <option value="so">Somalia</option>
                  <option value="za">South Africa</option>
                  <option value="es">Spain</option>
                  <option value="lk">Sri Lanka</option>
                  <option value="sh">St Helena</option>
                  <option value="kn">St Kitts and Nevis</option>
                  <option value="lc">St Lucia</option>
                  <option value="pm">St Pierre and Miquelon</option>
                  <option value="vc">St Vincent and Grenadines</option>
                  <option value="sr">Suriname</option>
                  <option value="sz">Swaziland</option>
                  <option value="se">Sweden</option>
                  <option value="ch">Switzerland</option>
                  <option value="tw">Taiwan, Rep of China</option>
                  <option value="tj">Tajikistan</option>
                  <option value="tz">Tanzania</option>
                  <option value="th">Thailand</option>
                  <option value="tl">Timor-Leste</option>
                  <option value="tg">Togo</option>
                  <option value="tk">Tokelau</option>
                  <option value="to">Tonga</option>
                  <option value="tt">Trinidad and Tobago</option>
                  <option value="tn">Tunisia</option>
                  <option value="tr">Turkey</option>
                  <option value="tm">Turkmenistan</option>
                  <option value="tc">Turks and Caicos Islands</option>
                  <option value="tv">Tuvalu</option>
                  <option value="ug">Uganda</option>
                  <option value="ae">United Arab Emirates</option>
                  <option value="gb">United Kingdom</option>
                  <option value="vi">United States Virgin Islands</option>
                  <option value="us">United States of America</option>
                  <option value="uy">Uruguay</option>
                  <option value="uz">Uzbekistan</option>
                  <option value="vu">Vanuatu</option>
                  <option value="va">Vatican City</option>
                  <option value="ve">Venezuela</option>
                  <option value="vn">Viet Nam</option>
                  <option value="wf">Wallis and Futuna Islands</option>
                  <option value="eh">Western Sahara</option>
                  <option value="zm">Zambia</option>
                  <option value="zw">Zimbabwe</option>
                  <option value="ss">South Sudan</option>
                  {/* Add more countries as needed */}
                </select>
                {submitted && !formData.country.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
            </div>
            <div className="pt-2 py-4 grid grid-cols-4 gap-4 w-full">
              <div className="col-span-4 grid grid-cols-2 gap-4">
                <div className="max-md:col-span-4">
                  <label htmlFor="birthMotherDOB" className="block mb-1">
                    تاريخ ميلاد الأم <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="birthMotherDOB"
                    id="birthMotherDOB"
                    value={formData.birthMotherDOB}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                  {submitted && !formData.birthMotherDOB && (
                    <p className="text-red-500 text-sm mt-1">
                      هذا الحقل مطلوب.
                    </p>
                  )}
                </div>
                <div className="max-md:col-span-4">
                  <label htmlFor="expectedDueDate" className="block mb-1">
                    تاريخ الولادة المتوقع{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDueDate"
                    id="expectedDueDate"
                    value={formData.expectedDueDate}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                  {submitted && !formData.expectedDueDate && (
                    <p className="text-red-500 text-sm mt-1">
                      هذا الحقل مطلوب.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-4">
                <label htmlFor="referral" className="block mb-1">
                  كيف سمعت عنا؟ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="referral"
                  id="referral"
                  value={formData.referral}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {submitted && !formData.referral.trim() && (
                  <p className="text-red-500 text-sm mt-1">هذا الحقل مطلوب.</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full pt-8">
            <h4 className="text-lg font-semibold mb-2 capitalize max-md:text-sm">
              الخطوة 2: العنوان ومعلومات المستشفى
            </h4>
            <div className="pt-2 grid grid-cols-4 gap-4 w-full">
              <div className="col-span-4 grid grid-cols-2 gap-4 max-md:text-sm">
                <div className="col-span-2 flex flex-wrap gap-4">
                  <p className="text-base col-span-2 w-full max-md:text-sm">
                    إلى أين تريد منا شحن مجموعة الأدوات الخاصة بك؟ الشحن مجاني!
                  </p>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="addressInfo"
                      id="sameAddress"
                      value="same"
                      checked={formData.addressInfo === "same"}
                      onChange={handleRadioChange}
                      className="border border-gray-300 rounded-md px-3 py-2"
                    />
                    <label htmlFor="sameAddress" className="block">
                      نفس العنوان كبيانات الاتصال
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="addressInfo"
                      id="newAddress"
                      value="new"
                      checked={formData.addressInfo === "new"}
                      onChange={handleRadioChange}
                      className="border border-gray-300 rounded-md px-3 py-2"
                    />
                    <label htmlFor="newAddress" className="block">
                      أدخل عنوانًا جديدًا
                    </label>
                  </div>
                </div>
                {formData.addressInfo === "new" && (
                  <div className="col-span-4 grid grid-cols-2 gap-4 max-md:text-sm">
                    <div className="col-span-2 flex flex-wrap gap-4">
                      <div className="max-md:col-span-4">
                        <label htmlFor="newAddressField" className="block mb-1">
                          العنوان
                        </label>
                        <input
                          type="text"
                          name="newAddress"
                          id="newAddressField"
                          value={formData.newAddress.address}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              newAddress: {
                                ...prevData.newAddress,
                                address: e.target.value,
                              },
                            }))
                          }
                          required
                          className="border border-gray-300 rounded-md px-3 py-2 w-full"
                        />
                      </div>
                      <div className="max-md:col-span-4">
                        <label htmlFor="newCity" className="block mb-1">
                          المدينة
                        </label>
                        <input
                          type="text"
                          name="newCity"
                          id="newCity"
                          value={formData.newAddress.city}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              newAddress: {
                                ...prevData.newAddress,
                                city: e.target.value,
                              },
                            }))
                          }
                          required
                          className="border border-gray-300 rounded-md px-3 py-2 w-full"
                        />
                      </div>
                      <div className="max-md:col-span-4">
                        <label htmlFor="newZipCode" className="block mb-1">
                          الرمز البريدي
                        </label>
                        <input
                          type="text"
                          name="newZipCode"
                          id="newZipCode"
                          value={formData.newAddress.zipCode}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              newAddress: {
                                ...prevData.newAddress,
                                zipCode: e.target.value,
                              },
                            }))
                          }
                          required
                          className="border border-gray-300 rounded-md px-3 py-2 w-full"
                        />
                      </div>
                      <div className="max-md:col-span-4">
                        <label htmlFor="newCountry" className="block mb-1">
                          الدولة
                        </label>
                        <select
                          name="newCountry"
                          id="newCountry"
                          value={formData.newAddress.country}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              newAddress: {
                                ...prevData.newAddress,
                                country: e.target.value,
                              },
                            }))
                          }
                          required
                          className="border border-gray-300 rounded-md px-3 py-2 w-full"
                        >
                          <option value="">--اختر--</option>
                          <option value="af">Afghanistan</option>
                          <option value="al">Albania</option>
                          <option value="dz">Algeria</option>
                          <option value="as">American Samoa</option>
                          <option value="ad">Andorra</option>
                          <option value="ao">Angola</option>
                          <option value="ai">Anguilla</option>
                          <option value="ag">Antigua and Barbuda</option>
                          <option value="ar">Argentina</option>
                          <option value="am">Armenia</option>
                          <option value="aw">Aruba</option>
                          <option value="au">Australia</option>
                          <option value="at">Austria</option>
                          <option value="az">Azerbaijan</option>
                          <option value="bs">Bahamas</option>
                          <option value="bh">Bahrain</option>
                          <option value="bd">Bangladesh</option>
                          <option value="bb">Barbados</option>
                          <option value="by">Belarus</option>
                          <option value="be">Belgium</option>
                          <option value="bz">Belize</option>
                          <option value="bj">Benin</option>
                          <option value="bm">Bermuda</option>
                          <option value="bt">Bhutan</option>
                          <option value="bo">Bolivia</option>
                          <option value="ba">Bosnia and Herzegovina</option>
                          <option value="bw">Botswana</option>
                          <option value="br">Brazil</option>
                          <option value="io">
                            British Indian Ocean Territory
                          </option>
                          <option value="vg">British Virgin Islands</option>
                          <option value="bn">Brunei Darussalam</option>
                          <option value="bg">Bulgaria</option>
                          <option value="bf">Burkina Faso</option>
                          <option value="bi">Burundi</option>
                          <option value="kh">Cambodia</option>
                          <option value="cm">Cameroon</option>
                          <option value="ca">Canada</option>
                          <option value="cv">Cape Verde</option>
                          <option value="ky">Cayman Islands</option>
                          <option value="cf">Central African Rep</option>
                          <option value="td">Chad</option>
                          <option value="cl">Chile</option>
                          <option value="cn">China</option>
                          <option value="cx">Christmas Island</option>
                          <option value="cc">Cocos (Keeling) Islands</option>
                          <option value="co">Colombia</option>
                          <option value="km">Comoros</option>
                          <option value="cd">Congo, Democratic Rep of</option>
                          <option value="cg">Congo, Republic of</option>
                          <option value="ck">Cook Islands</option>
                          <option value="cr">Costa Rica</option>
                          <option value="ci">Cote d'Ivoire</option>
                          <option value="hr">Croatia</option>
                          <option value="cy">Cyprus</option>
                          <option value="cz">Czech Rep</option>
                          <option value="dk">Denmark</option>
                          <option value="dj">Djibouti</option>
                          <option value="dm">Dominica</option>
                          <option value="do">Dominican Rep</option>
                          <option value="ec">Ecuador</option>
                          <option value="eg">Egypt</option>
                          <option value="sv">El Salvador</option>
                          <option value="gq">Equatorial Guinea</option>
                          <option value="er">Eritrea</option>
                          <option value="ee">Estonia</option>
                          <option value="et">Ethiopia</option>
                          <option value="fk">Falkland Islands</option>
                          <option value="fo">Faroe Islands</option>
                          <option value="fj">Fiji</option>
                          <option value="fi">Finland</option>
                          <option value="fr">France</option>
                          <option value="gf">French Guyana</option>
                          <option value="pf">French Polynesia</option>
                          <option value="ga">Gabon</option>
                          <option value="gm">Gambia</option>
                          <option value="ge">Georgia</option>
                          <option value="de">Germany</option>
                          <option value="gh">Ghana</option>
                          <option value="gi">Gibraltar</option>
                          <option value="gr">Greece</option>
                          <option value="gl">Greenland</option>
                          <option value="gd">Grenada</option>
                          <option value="gp">Guadeloupe</option>
                          <option value="gu">Guam</option>
                          <option value="gt">Guatemala</option>
                          <option value="gn">Guinea</option>
                          <option value="gw">Guinea-Bissau</option>
                          <option value="gy">Guyana</option>
                          <option value="ht">Haiti</option>
                          <option value="hn">Honduras</option>
                          <option value="hk">Hong Kong</option>
                          <option value="hu">Hungary</option>
                          <option value="is">Iceland</option>
                          <option value="in">India</option>
                          <option value="id">Indonesia</option>
                          <option value="iq">Iraq</option>
                          <option value="ie">Ireland</option>
                          <option value="il">Israel</option>
                          <option value="it">Italy</option>
                          <option value="jm">Jamaica</option>
                          <option value="jp">Japan</option>
                          <option value="jo">Jordan</option>
                          <option value="kz">Kazakhstan</option>
                          <option value="ke">Kenya</option>
                          <option value="ki">Kiribati</option>
                          <option value="kr">Korea, South</option>
                          <option value="kw">Kuwait</option>
                          <option value="kg">Kyrgyzstan</option>
                          <option value="la">Laos</option>
                          <option value="lv">Latvia</option>
                          <option value="lb">Lebanon</option>
                          <option value="ls">Lesotho</option>
                          <option value="lr">Liberia</option>
                          <option value="ly">Libya</option>
                          <option value="li">Liechtenstein</option>
                          <option value="lt">Lithuania</option>
                          <option value="lu">Luxembourg</option>
                          <option value="mo">Macau</option>
                          <option value="mk">Macedonia</option>
                          <option value="mg">Madagascar</option>
                          <option value="mw">Malawi</option>
                          <option value="my">Malaysia</option>
                          <option value="mv">Maldives</option>
                          <option value="ml">Mali</option>
                          <option value="mt">Malta</option>
                          <option value="mh">Marshall Islands</option>
                          <option value="mq">Martinique</option>
                          <option value="mr">Mauritania</option>
                          <option value="mu">Mauritius</option>
                          <option value="yt">Mayotte</option>
                          <option value="mx">Mexico</option>
                          <option value="fm">Micronesia</option>
                          <option value="md">Moldova, Rep of</option>
                          <option value="mc">Monaco</option>
                          <option value="mn">Mongolia</option>
                          <option value="me">Montenegro</option>
                          <option value="ms">Montserrat</option>
                          <option value="ma">Morocco</option>
                          <option value="mz">Mozambique</option>
                          <option value="mm">Myanmar</option>
                          <option value="na">Namibia</option>
                          <option value="nr">Nauru</option>
                          <option value="np">Nepal</option>
                          <option value="nl">Netherlands</option>
                          <option value="an">Netherlands Antilles</option>
                          <option value="nc">New Caledonia</option>
                          <option value="nz">New Zealand</option>
                          <option value="ni">Nicaragua</option>
                          <option value="ne">Niger</option>
                          <option value="ng">Nigeria</option>
                          <option value="nu">Niue</option>
                          <option value="nf">Norfolk Island</option>
                          <option value="mp">Northern Mariana Islands</option>
                          <option value="no">Norway</option>
                          <option value="om">Oman</option>
                          <option value="pk">Pakistan</option>
                          <option value="pw">Palau</option>
                          <option value="ps">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="pa">Panama</option>
                          <option value="pg">Papua New Guinea</option>
                          <option value="py">Paraguay</option>
                          <option value="pe">Peru</option>
                          <option value="ph">Philippines</option>
                          <option value="pn">Pitcairn Islands</option>
                          <option value="pl">Poland</option>
                          <option value="pt">Portugal</option>
                          <option value="pr">Puerto Rico</option>
                          <option value="qa">Qatar</option>
                          <option value="re">Reunion</option>
                          <option value="ro">Romania</option>
                          <option value="rw">Rwanda</option>
                          <option value="ws">Samoa</option>
                          <option value="sm">San Marino</option>
                          <option value="st">Sao Tome and Principe</option>
                          <option value="sa">Saudi Arabia</option>
                          <option value="sn">Senegal</option>
                          <option value="rs">Serbia</option>
                          <option value="sc">Seychelles</option>
                          <option value="sl">Sierra Leone</option>
                          <option value="sg">Singapore</option>
                          <option value="sk">Slovakia</option>
                          <option value="si">Slovenia</option>
                          <option value="sb">Solomon Islands</option>
                          <option value="so">Somalia</option>
                          <option value="za">South Africa</option>
                          <option value="es">Spain</option>
                          <option value="lk">Sri Lanka</option>
                          <option value="sh">St Helena</option>
                          <option value="kn">St Kitts and Nevis</option>
                          <option value="lc">St Lucia</option>
                          <option value="pm">St Pierre and Miquelon</option>
                          <option value="vc">St Vincent and Grenadines</option>
                          <option value="sr">Suriname</option>
                          <option value="sz">Swaziland</option>
                          <option value="se">Sweden</option>
                          <option value="ch">Switzerland</option>
                          <option value="tw">Taiwan, Rep of China</option>
                          <option value="tj">Tajikistan</option>
                          <option value="tz">Tanzania</option>
                          <option value="th">Thailand</option>
                          <option value="tl">Timor-Leste</option>
                          <option value="tg">Togo</option>
                          <option value="tk">Tokelau</option>
                          <option value="to">Tonga</option>
                          <option value="tt">Trinidad and Tobago</option>
                          <option value="tn">Tunisia</option>
                          <option value="tr">Turkey</option>
                          <option value="tm">Turkmenistan</option>
                          <option value="tc">Turks and Caicos Islands</option>
                          <option value="tv">Tuvalu</option>
                          <option value="ug">Uganda</option>
                          <option value="ae">United Arab Emirates</option>
                          <option value="gb">United Kingdom</option>
                          <option value="vi">
                            United States Virgin Islands
                          </option>
                          <option value="us">United States of America</option>
                          <option value="uy">Uruguay</option>
                          <option value="uz">Uzbekistan</option>
                          <option value="vu">Vanuatu</option>
                          <option value="va">Vatican City</option>
                          <option value="ve">Venezuela</option>
                          <option value="vn">Viet Nam</option>
                          <option value="wf">Wallis and Futuna Islands</option>
                          <option value="eh">Western Sahara</option>
                          <option value="zm">Zambia</option>
                          <option value="zw">Zimbabwe</option>
                          <option value="ss">South Sudan</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full pt-8 border-b pb-8">
              <h4 className="text-lg font-semibold mb-2 capitalize max-md:text-sm">
                الخطوة 3: الدفع
              </h4>
              <div className="pt-2 grid grid-cols-4 gap-4 w-full">
                <div className="col-span-4 grid grid-cols-2 gap-4">
                  {/* هنا يمكن إضافة معلومات عنوان الدفع في حال الحاجة */}
                </div>
              </div>
            </div>
            <p className="text-base pt-8 w-full max-md:text-sm">
              بالنقر على "المتابعة للدفع"، أوافق على سياسة استرداد المبالغ
              الخاصة بـ سيل سيف.
            </p>
            <p className=" opacity-70">
              رسوم الاشتراك هي دفعة مسبقة غير قابلة للاسترداد ويجب تقديمها مع
              اتفاقية خدمة العميل الموقعة.
            </p>
            <p className=" opacity-70">
              في حال تم جمع العينة وتسلمها ومعالجتها، ثم رفضت سيل سيف العينة
              لعدم امتثالها للمعايير التنظيمية، لن يتم التخزين وستقوم سيل سيف
              برد رسوم التخزين للعميل. رسوم المعالجة غير قابلة للاسترداد إذا تم
              بالفعل معالجة وفحص عينة دم الحبل السري (وأو، إذا كان ذلك ممكنًا،
              عينة نسيج الحبل/نسيج المشيمة/نسيج السلى في المشيمة/الأوعية
              السرية).
            </p>
            <div className="flex gap-5 pt-8">
              <div
                onClick={() => handleSubmit()}
                className=" cursor-pointer bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl max-md:px-4"
              >
                {loading ? "جاري التحميل..." : "المتابعة للدفع"}
              </div>
              <div
                onClick={() => handleChange("secondStep")}
                className="bg-gradient-to-r from-custom-primary to-custom-secondary transition-all p-[1px] group hover:text-white text-base rounded-3xl"
              >
                <div className="bg-white group-hover:bg-transparent px-8 py-2 rounded-3xl max-md:px-4">
                  <span className="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text group-hover:text-white transition-all font-semibold">
                    السابق
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/6 pr-12 max-lg:w-full max-lg:pl-0 max-lg:mt-4 max-md:mb-4 max-md:order-first">
          <div className="border rounded-lg py-8 px-4">
            <h3 className="text-custom-primary text-xl font-semibold capitalize">
              ملخص الطلب
            </h3>
            <div className="flex flex-wrap border-b py-8 items-center justify-between">
              <img
                src={assets.checkoutProduct}
                alt={"Cellsave Kit"}
                className="w-6/12 max-md:w-full"
              />
              <h3 className="w-5/12 text-xl font-semibold">
                {order.selectedProduct &&
                  data[order.selectedProduct].attributes.Title}
              </h3>
            </div>
            <div className="flex flex-col gap-2 py-4">
              <div className="flex flex-wrap items-center justify-between">
                <h4 className="text-base">السعر الإجمالي</h4>
                <h4 className="text-base font-semibold">
                  {order.totalPrice &&
                    order.totalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  درهم
                </h4>
              </div>
              <div className="flex flex-wrap items-center justify-between">
                <h4 className="text-base">المبلغ المستحق الآن</h4>
                <h4 className="text-base font-semibold">
                  {order.duenow &&
                    order.duenow.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  درهم
                </h4>
              </div>
              {order.duemonthly && (
                <div className="flex flex-wrap items-center justify-between">
                  <h4 className="text-base">المبلغ الشهري المستحق</h4>
                  <h4 className="text-base font-semibold">
                    {order.duemonthly.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    درهم
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
